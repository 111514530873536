import React from "react";
import Layout from "../components/layout";
import "react-tabs/style/react-tabs.css";

const IndexPage = () => (
  <Layout>
    <hr />
   
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfoRzQpOGy27Vr9GCRKv6TOOh-xgjF1DnGF_Ye4SHdvS8wnaw/viewform?embedded=true"
      width="100%"
      height="903"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      style={{overflow:"hidden"}}
    >
      Loading…
    </iframe>
  </Layout>
);

export default IndexPage;
